/* eslint-disable consistent-return */
/*
    Middleware to check if a code query is present.
*/
export default defineNuxtRouteMiddleware((to) => {
    const { query } = to;

    if (!query.code) {
        const localePath = useLocalePath();
        return navigateTo(localePath('/login'));
    }
});
